<template>
  <div class="question-image-uploader">
    <label v-if="title" class="col-form-label pl-0 question-title">{{ title }} </label>
    <div class="anzera-ai-image filePreview">
      <img v-if="imagePreview" :src="imagePreview" alt="Görsel Önizleme" class="image-preview"/>
      <!--      <div v-else v-html="preview"/>-->
    </div>

    <div class="btn-group mt-2">
      <a aria-current="page" class="btn btn-primary btn-sm" href="#" @click.prevent="triggerFileInput">
        <i class="uil-plus"></i> {{ $t('general.add_file') }}</a>
      <input ref="fileInput" accept=".jpeg, .jpg, .png, .gif" class="btn btn-primary btn-sm d-none" type="file"
             @change="handleManualImageUpload($event)">
      <a class="btn btn-secondary btn-sm" href="#" @click.prevent="openModal"><i
          class="uil-robot"></i> {{ $t('general.create_anzera') }}</a>
    </div>

    <!-- Modal -->
    <div id="anzerapower-image-modal" ref="imageUploadModalRef" uk-modal>
      <div class="uk-modal-dialog uk-modal-body">
        <anzera-power-chat file="image" @handleQuestions="handleAnzeraAIImageSelection" @onCancelled="closeModal"
                           :is-single-selection="true" :imageTranslateText="imageTranslateText"/>
      </div>
    </div>

  </div>
</template>

<script>
import AnzeraPowerChat from "@/view/components/anzera-power/anzera-power-chat/anzera-power-chat.vue";
import {getEnvironmentMediaUrl} from "@/utils/imageHelper";
import store from "@/core/services";
import {ITEMS, MODULE_NAME} from "@/core/services/store/faq.module";

export default {
  name: 'QuestionImageUploader',
  components: {AnzeraPowerChat},
  props: {
    questionIndex: {
      type: Number,
      required: true
    },
    optionIndex: {
      type: Number,
      required: false
    },
    defaultImage: {
      type: [String, File, null],
      default: null
    },
    coverImg: {
      type: [String, File, null],
      default: 'https://placehold.co/120'
    },
    title: {
      type: String,
      default: ''
    },
    imageTranslateText: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      imagePreview: this.coverImg
    };
  },
  methods: {
    handleManualImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.imagePreview = URL.createObjectURL(file);
      }
      this.$emit('upload-image', {image_url: file, questionIndex: this.questionIndex, optionIndex: this.optionIndex});
    },
    handleAnzeraAIImageSelection({type, data}) {
      if (type === 'image') {
        this.imagePreview = data[0].image_url
      }
      this.$emit('upload-image', {
        image_url: data[0].image_url,
        questionIndex: this.questionIndex,
        optionIndex: this.optionIndex
      });

      this.closeModal()
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    openModal() {
      this.$nextTick(() => {
        const modalInstance = UIkit.modal(this.$refs.imageUploadModalRef);
        modalInstance.show();

        this.$refs.imageUploadModalRef.addEventListener('hidden', this.onClose);
      });

    },
    closeModal() {
      const modalInstance = UIkit.modal(this.$refs.imageUploadModalRef);
      modalInstance.hide(); // trigger to onClose to catch events etc
    },
    onClose() {
      // (note :bu kullanım UIKit reaktif sistemine daha uyumlu )
      this.$refs.imageUploadModalRef.removeEventListener('hidden', this.onClose);
    },
  },
  watch: {
    defaultImage: {
      handler(newVal) {

        if (newVal instanceof File) {
          this.imagePreview = URL.createObjectURL(newVal);
        } else if (typeof newVal === 'string' && newVal.trim() !== '') {
          //if comes from BE or Anzera ai
          if (newVal.startsWith('http')) {
            // Eğer zaten bir URL ise, olduğu gibi kullan
            this.imagePreview = newVal;
          } else {
            // Backend'den gelen dosya adıysa, API URL'si ile birleştir
            this.imagePreview = getEnvironmentMediaUrl(newVal);
          }
        } else {
          this.imagePreview = this.coverImg;
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    //custom-body-height class is to protect position when modal is opened
    document.body.classList.add('custom-body-height');
  },
  beforeDestroy() {
    document.body.classList.remove('custom-body-height');
  }
}
</script>

<style>
.custom-body-height {
  height: 100% !important;
}

.question-image-uploader {
  width: 100%;
}

.anzera-ai-image {
  width: 100%;
  height: 150px;
  background-color: #DDDDDD;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-preview {
  width: 100%;
  max-height: 150px;
  object-fit: contain;
}

#anzerapower-image-modal {
  .question-count-select, .file-type-select, .question-count, .choose-all-btn {
    display: none;
  }

  .anzera-submit-buttons {
    display: flex;
    justify-content: end;
    width: 100%;
  }

}
</style>
