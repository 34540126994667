export function getEnvironmentMediaUrl(imagePath) {
    if (!imagePath) return '';

    if (imagePath.startsWith('http')) return imagePath

    const isProduction = process.env.NODE_ENV === 'production';

    // sınav modulu için oluşturuldu, AI ile üretilen sınavlara ekeleen resimler
    const baseUrl = isProduction
            ? 'https://cdn.anzera.com.tr/softinya'
            : 'https://api.zorunluegitimler.com/storage'

    return `${baseUrl}/${imagePath}`;
}