<template>
  <div class="anzera-question-card">
    <div class="message-container received-message">
      <div class="uk-flex uk-flex-left uk-margin-small-bottom">
        <div class="profile-icon uk-margin-small-right">
          <img :src="'/media/images/icon-anzerapower.png'"/>
        </div>
        <div class="question-card uk-card uk-card-primary uk-card-body uk-padding-small">
          <div class="uk-flex uk-flex-middle uk-margin-small-bottom mb-0">
            <div>
              <p>{{ $t('general.anzera_info') }}</p>
              <p>{{ $t('general.anzera_sub_info') }} </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "anzera-question-card",
}
</script>

<style scoped>

.uk-card-primary p {
  color: #ffffff !important;
}

.message-container {
  margin-bottom: 20px;
}

.received-message {
  display: flex;
  justify-content: flex-start;
}

.profile-icon {
  width: 50px;
  height: 50px;
  background: #666CFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-icon img {
  padding: 0.2rem;
}

.message-content .profile-icon {
  background: #fff;
}

.question-card {
  background: #666CFF !important;
  border-radius: 12px;
  color: white;
  max-width: 65%;
}

.question-card input {
  width: 50px;
}



</style>
