<template>
  <div class="question-demo">

    <template v-for="(question, questionIndex) in getNewQuestions">
      <div :class="'card_' + questionIndex" class="card">

        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center mb-2">
            <h5 class="card-title mb-0">{{ $t('general.question') }} #{{ questionIndex + 1 }}</h5>
            <a :uk-tooltip="$t('general.remove_from_list')" aria-expanded="false"
               class="btn btn-icon btn-lg btn-circle" href="javascript:void(0)" title=""
               @click="removeQuestionRow(questionIndex)">
              <i class="uil-trash-alt text-danger"></i>
            </a>
          </div>

          <div class="form-group row mb-0">
            <div class="col-md-8">
              <div class="row">
                <label :for="'type_' + questionIndex"
                       class="col-md-3 col-form-label">{{ $t('question.attributes.q_type') }}<span
                    class="required">*</span></label>
                <div class="col-md-9">
                  <select :id="'type_' + questionIndex" v-model="question.type" class="selectpicker1"
                          @change="changedQuestionType(questionIndex)">
                    <option v-for="questionType in questionTypes" :value="questionType.id">
                      <i :class="questionType.icon"></i> {{ questionType.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div v-if="false" class="col-md-4">
              <div class="row">
                <label class="col-md-6 col-form-label">Soru Puanı<span class="required">*</span></label>
                <div class="col-md-6">
                  <input v-model.number="question.questionScore" type="number">
                </div>
              </div>
              <div v-if="false" class="row">
                <label class="col-md-6 col-form-label">Soru Süresi (sn)<span class="required">*</span></label>
                <div class="col-md-6">
                  <input v-model.number="question.questionDuration" type="number">
                </div>
              </div>
            </div>
          </div>

          <!--Ses Dosyası-->
          <div v-if="false" class="form-group row mb-3">
            <label class="col-md-2 col-form-label">{{ $t('general.audio_file') }} <span
                class="required">*</span></label>
            <div class="col-md-10">
              <div class="uploadOuter">
                <label :for="'questionAudioUpload_' + questionIndex" class="btn btn-info"><i class="uil-upload"></i> {{
                    $t('general.upload')
                  }}</label>
                <strong>{{ $t('general.or') }}</strong>
                <span class="dragBox">
                  <i class="icon-feather-file-plus"></i> {{ $t('general.audio_file_drag_drop') }}
                  <input :id="'questionAudioUpload_' + questionIndex" accept="audio/*" type="file"
                         @change="dragNdropAudio(questionIndex, $event)">
                </span>
              </div>
            </div>
            <div class="col-md-12">
              <div v-if="question.audioPreview" :id="'preview_audio_' + questionIndex" v-html="question.audioPreview">
              </div>
            </div>
          </div>
          <!--Video Dosyası-->
          <div v-if="false" class="form-group row mb-3">
            <label class="col-md-2 col-form-label">{{ $t('general.video_file') }} <span
                class="required">*</span></label>
            <div class="col-md-10">
              <div class="uploadOuter">
                <label :for="'questionVideoUpload_' + questionIndex" class="btn btn-info"><i class="uil-upload"></i>{{
                    $t('general.upload')
                  }}</label>
                <strong>{{ $t('general.or') }}</strong>
                <span class="dragBox">
                  <i class="icon-feather-file-plus"></i> {{ $t('general.video_file_drag_drop') }}
                  <input :id="'questionVideoUpload_' + questionIndex" accept="video/*" type="file"
                         @change="dragNdropVideo(questionIndex, $event)">
                </span>
              </div>
            </div>
            <div class="col-md-12">
              <div v-if="question.videoPreview" :id="'preview_video_' + questionIndex" v-html="question.videoPreview">
              </div>
            </div>
          </div>
          <!--Editor alanı-->
          <div class="form-group row mb-3">
            <div class="col-md-8">
              <label :for="'question_text_' + questionIndex" class="col-md-12 col-form-label pl-0">
                {{ $t('question.attributes.text') }} <span class="required">*</span></label>
              <!-- <textarea :id="'question_text_' + questionIndex" v-model="question.text" class="form-control"></textarea> -->
              <div id="app">
                <vue-editor v-model="question.text" @image-added="onImageUpload"></vue-editor>
              </div>
            </div>
            <div class="col-md-4">
              <question-image-uploader key="1" :default-image="question.media" :question-index="questionIndex"
                                       :title="$t('question.attributes.image')"
                                       :image-translate-text="$t('general.import_selected_images')"
                                       @upload-image="uploadQuestionCoverImage"/>
            </div>

          </div>

          <!-- Açık Uçlu Soru -->
          <!--          <div class="form-group row mb-3" v-if="question.type === 6">-->
          <!--            <label class="col-md-3 col-form-label" :for="'options_text_' + questionIndex">{{ $t('question.attributes.answer') }} <span class="required">*</span></label>-->
          <!--            <div class="col-md-9">-->
          <!--              <textarea v-model="question.options[0].name" :id="'options_text_' + questionIndex" class="form-control"></textarea>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--Soru Şıklerı-->
          <div v-if="question.type !== 6" class="form-group row mb-3">
            <label class="col-md-12 col-form-label">{{ $t('question.attributes.answer_options') }}
              <span
                  class="required">*</span></label>

            <div class="col-md-12">
              <table :id="'myTable_' + questionIndex" class="uk-table uk-table-striped uk-table-middle">
                <thead>
                <tr>
                  <td>{{ $t('question.attributes.answer_text') }}</td>
                  <td class="text-center">{{ $t('general.status') }}</td>
                  <td class="text-center">{{$t('general.stylish_visual')}}</td>
                  <td class="text-right">{{ $t('general.action') }}</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(option, optionIndex) in question.options">
                  <td class="col-4">
                    <input v-if="checkQuestionType(question.type)" v-model="option.name" class="form-control"
                           name="reply_text" required type="text">
                  </td>

                  <td class="col-1">
                    <div
                        class="d-flex align-content-center justify-content-center table-vertical-center align-items-center">
                      <toggle-button v-model="option.is_correct"
                                     :labels="{ checked: $t('question.attributes.true'), unchecked: $t('question.attributes.false') }"
                                     :sync="true" :width="80"
                                     color="#ae71ff"/>
                    </div>
                  </td>

                  <td class="question-option-image">

                    <question-image-uploader :default-image="option?.media"
                                             :option-index="optionIndex"
                                             :question-index="questionIndex"
                                             cover-img="https://placehold.co/64"
                                             :image-translate-text="$t('general.import_selected_images')"
                                             @upload-image="uploadQuestionOptionsImage"/>
                  </td>

                  <td class="col-1">
                    <div class="actions mr-2 float-right">
                      <a :uk-tooltip="$t('general.remove_from_list')" aria-expanded="false"
                         class="btn btn-icon btn-lg btn-circle" href="javascript:void(0)"
                         title="" @click="removeOptionRow(questionIndex, optionIndex)">
                        <i class="uil-times-circle text-danger"></i>
                      </a>
                    </div>
                  </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <td colspan="5" style="text-align: left;">
                    <input :value="$t('general.add')" class="btn btn-lg btn-outline-primary" type="button"
                           @click="addOptionRow(questionIndex)"/>
                  </td>
                </tr>
                <tr>
                </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div class="text-right">
      <button class="btn mb-2 w-100" type="button" @click="addQuestionRow">
        <i class="icon-feather-plus-circle"></i> {{ $t('general.add_question') }}
      </button>
    </div>

  </div>
</template>

<script>
import {VueEditor} from "vue2-editor";
import store from "@/core/services";
import QuestionImageUploader from "@/view/components/question/QuestionImageUploader.vue";
import {
  GET_ITEMS as QUESTION_REST_ITEMS,
  LOADING as QUESTION_REST_LOADING,
  ERROR as QUESTION_REST_ERROR,
} from "@/core/services/store/REST.module";

export default {
  name: "Questions",
  components: {
    VueEditor, QuestionImageUploader
  },

  // beforeCreate () {
  //   function registerStoreModule (moduleName, storeModule) {
  //     if (!(store && store.state && store.state[moduleName])) {
  //       store.registerModule(moduleName, storeModule)
  //     }
  //   }
  //   registerStoreModule(QUESTION_REST_MODULE_NAME, module)
  // },
  props: {
    importedExam: [],
    modelType: {
      required: true,
      default: '', // Survey or Exam
    },
    action: {
      required: true,
      default: '',
    },
    questions: {
      required: true,
      default: [],
    }
  },
  data() {
    return {
      questionTypes: [],
      newQuestions: [],
      questionForm: {
        id: 0,
        type: 1,
        text: '',
        media: '',
        options: [{id: 0, name: '', is_correct: false, media: null}],
        preview: null,
        audioPreview: null,
        videoPreview: null,
        previewReply: [],
        questionScore: null,
        questionDuration: null
      },
    }
  },
  computed: {
    errors: {
      get() {
        return store.getters[QUESTION_REST_ERROR]
      },
      set(value) {
      }
    },
    loading() {
      return store.getters[QUESTION_REST_LOADING]
    },
    getNewQuestions() {

      if (this.action === 'edit') {
        if (this.newQuestions.length === 0) {
          this.newQuestions = this.questions;
        }
      }
      if (this.action === 'create') {
        if (this.newQuestions.length === 0) {
          debugger
          let myForm = Object.assign({}, this.questionForm);
          this.newQuestions.push(myForm);
        }
      }
      return this.newQuestions;
    },
  },
  methods: {
    onImageUpload(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64String = e.target.result;
        this.insertImageToEditor(base64String);
      };

      reader.readAsDataURL(file);
    },
    insertImageToEditor(base64String) {
      // img tagini base64 ile oluşturma
      const imgTag = `<img src="${base64String}" alt="Image"/>`;

      // Editöre HTML olarak ekleme
      this.$refs.editor.quillEditor.clipboard.dangerouslyPasteHTML(this.$refs.editor.quillEditor.getSelection().index, imgTag);
    },
    handleNewQuestions() {
      // refs for parent component
      return this.newQuestions;
    },
    addQuestionRow() {
      let myForm = Object.assign({}, this.questionForm);
      myForm.options = [{id: 0, name: '', is_correct: false, media: null}];
      this.newQuestions.push(myForm);
    },
    removeQuestionRow(questionIndex) {
      this.newQuestions.splice(questionIndex, 1);
    },
    addOptionRow(questionIndex) {
      this.newQuestions[questionIndex].options.push({id: 0, name: "", is_correct: false, media: null});
    },
    removeOptionRow(questionIndex, optionIndex) {
      this.newQuestions[questionIndex].options.splice(optionIndex, 1);
    },
    changedQuestionType(questionIndex) {

      this.newQuestions[questionIndex].media = '';
      this.newQuestions[questionIndex].preview = '';
      this.newQuestions[questionIndex].audioPreview = '';
      this.newQuestions[questionIndex].videoPreview = '';
      this.newQuestions[questionIndex].previewReply = [];
      this.newQuestions[questionIndex].options = [{id: 0, name: '', is_correct: false, media: null}];

      $("div.card_" + questionIndex).find("div[class^='preview_']").html("");
      $("div.card_" + questionIndex).find('input[type="file"]').val("");

    },
    checkQuestionType(questionType) {
      let arr = [1, 2, 3, 7, 8];

      return arr.includes(questionType);
    },
    uploadQuestionCoverImage(image) {
      const {image_url, questionIndex} = image
      this.newQuestions[questionIndex].media = image_url;
    },
    uploadQuestionOptionsImage(image) {
      const {image_url, questionIndex, optionIndex} = image
      this.newQuestions[questionIndex].options[optionIndex].media = image_url;
    },
    dragNdropAudio(questionIndex, event) {
      let file = event.target.files[0];
      this.newQuestions[questionIndex].media = file;

      this.newQuestions[questionIndex].audioPreview = '<div class="file-info">' +
          '<div>Format: <span>' + file.type + ' <i class="uil-microphone" style="font-size: 14px"></i></span></div>' +
          '<div>Ses Dosyası Adı: <span>' + file.name + '</span></div>' +
          '<div>Boyut: <span>' + this.bytesToSize(file.size) + '</span></div>' +
          '</div>';
    },
    dragNdropVideo(questionIndex, event) {
      let file = event.target.files[0];
      this.newQuestions[questionIndex].media = file;

      this.newQuestions[questionIndex].videoPreview = '<div class="file-info">' +
          '<div>Format: <span>' + file.type + ' <i class="uil-video" style="font-size: 14px"></i></span></div>' +
          '<div>Video Dosyası Adı: <span>' + file.name + '</span></div>' +
          '<div>Boyut: <span>' + this.bytesToSize(file.size) + '</span></div>' +
          '</div>';
    },

  },
  mounted() {
    store.dispatch(QUESTION_REST_ITEMS, {
      url: 'api/question_types',
    }).then(response => {
      if (response.status) {
        this.questionTypes = response.data;
        // Açık uçlu soru tipi listeden kaldırılıyor
        this.questionTypes = this.questionTypes.filter((item, index) => {
          return item.id === 1;
        });
      }
      // Eşleştirme/Sıralama  --  Evet/Hayır - Doğru/Yanlış -- Açık Uçlu
    })
    debugger
    //this.getNewQuestions();
  },
  watch: {
    importedExam: function (examQuestion) {
      this.action = 'create';
      this.newQuestions = examQuestion
    },

  },
}

</script>

<style lang="scss">

.question-demo {

  .anzera-ai-image {
    width: auto;
    height: 150px;
    background-color: #DDDDDD;
    overflow: hidden;
    object-fit: contain;
  }

  .question-option-image {
    display: flex;

    .anzera-ai-image {
      width: 64px !important;
      height: 64px !important;

      .question-image-uploader {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
      }
    }
  }
}

</style>
