<template>
  <div class="app-bread-crumb">
    <div class="d-flex">
      <nav id="breadcrumbs" class="mb-3">
        <ul>
          <li>
            <a href="#"> <i :class="icon"/> </a>
          </li>
          <template v-for="(item,index)  in list">
            <li :key="index">{{ item.name }}</li>
          </template>
        </ul>
      </nav>
    </div>
  </div>
</template>


<script>
export default {
  name: "AppBreadCrumb",
  props: {
    list: {
      type: Array,
      default: () => []
    },
    icon: {
      type: String,
      default: 'uil-home-alt'
    }
  }
}
</script>


<style scoped>

</style>