<template>
  <div class="anzera-question-card">
    <anzera-question-card-skeleton v-if="!questionList.length" />
    <!-- User Message -->

    <div v-else  >
      <div v-for="(group, groupId) in groupedQuestions" :key="`groupId-${groupId}`" class="anzera-question-cards-wrapper">

        <div class="message-container sent-message">
          <div class="message-content sent uk-flex uk-flex-middle">
            <div class="message-text uk-margin-small-right">
              <span>{{ group[0].askedQuestion }}</span>
            </div>
            <div class="profile-icon">
              <img :src="'/media/images/icon-user.png'"/>
            </div>
          </div>
        </div>

        <!-- AnzeraPower Message -->
        <template>
          <div class="message-container received-message">
            <div class="uk-flex uk-flex-left uk-margin-small-bottom">
              <div class="profile-icon uk-margin-small-right">
                <img :src="'/media/images/icon-anzerapower.png'"/>
              </div>
              <div class="question-card uk-card uk-card-primary uk-card-body uk-padding-small">
                <div  v-for="(data, index) in group"  :key="`group-${index}`">
                  <div class="uk-flex uk-flex-middle uk-margin-small-bottom">
                    <input type="checkbox" :value="data.orderNo" v-model="selectedQuestions">

                    <p class="m-0">
                      <b>{{ $t('exam.question') }} {{ index + 1 }} </b><br>
                      {{ data.question  }}
                    </p>
                  </div>
                  <div v-if="data.options" class="options-list">
                    <div v-for="(opt, key) in data.options"
                         :key="key"
                         :class="{'option correct': key === data.correct_answer}">
                      <span>{{ key }}) {{ opt }}</span>
                    </div>
                  </div>
                  <!-- AnzeraPower Image Message -->
                  <div v-else-if="data.url" class="options-list">
                    <img :src="data.url">
                  </div>
                  <hr>
                </div>

              </div>
            </div>
          </div>
        </template>

      </div>
    </div>


  </div>

</template>

<script>
import AnzeraQuestionCardSkeleton
  from "@/view/components/anzera-power/anzera-question-card/anzera-question-card-skeleton.vue";
import {nextTick} from "vue";

export default {
  name: "anzera-question-card",
  components: {AnzeraQuestionCardSkeleton},
  props: {
    questionList: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      selectedQuestions: []
    }
  },
  computed: {
    groupedQuestions() {
      return this.questionList.reduce((groups, question) => {
        if (!groups[question.groupId]) {
          groups[question.groupId] = [];
        }
        groups[question.groupId].push(question);
        return groups;
      }, {});
    },
  },

  methods:{
    handleSelectedQuestions(){
      // refs for parent component
      return this.selectedQuestions
    },
    checkAllQuestions(){
      // refs for parent component
      this.selectedQuestions = this.questionList.map(q => q.orderNo);
    },
  },

}

</script>

<style scoped>
.anzera-question-cards-wrapper {
  .options-list img {
    width: 400px;
    height: 200px;
  }

  .uk-card-primary p {
    color: #ffffff !important;
  }

  .message-container {
    margin-bottom: 20px;
  }

  .sent-message {
    display: flex;
    justify-content: flex-end;
  }

  .received-message {
    display: flex;
    justify-content: flex-start;
  }

  .message-content {
    max-width: 80%;
    padding: 10px 15px;
    border-radius: 12px;
  }

  .message-content.sent {
    background: #eee;
    color: black;
  }

  .profile-icon {
    width: 50px;
    height: 50px;
    background: #666CFF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .profile-icon img {
    padding: 0.2rem;
  }

  .message-content .profile-icon {
    background: #fff;
  }

  .question-card {
    background: #666CFF !important;
    border-radius: 12px;
    color: white;
    max-width: 65%;
  }

  .question-card input {
    width: 50px;
  }

  .option {
    font-size: 0.75rem;
    margin-bottom: .2rem;
  }

  .option span {
    padding: .3rem;
  }

  .option.correct span {
    background-color: #28a745;
    border-radius: .4rem;;
  }

}
</style>