<template>
<div class="question-pool">
  <div class="card uk-width-4-4@m position-relative p-0">

    <div class="card-header actions-toolbar border-0">
      <div class="d-flex justify-content-between align-items-center">
        <div class="uk-search uk-search-navbar">
          <i class="uil-search position-absolute"></i>
          <input class="uk-search-input shadow-0 uk-form-small" type="search"/>
        </div>
        <div class="d-flex">
          <sort-dropdown :sort-options="sortOption" title="sortTitle" />
        </div>
      </div>
    </div>
    <div class="card-body">
      <table class="table align-items-center">
        <thead>
        <tr>
          <th scope="col">Soru</th>
          <th scope="col">Etiketler</th>
          <th scope="col">Ekle</th>
        </tr>
        </thead>
        <tbody class="list">
        <tr v-for="i in 3" :key="i">
          <td>There are many variations of passages of Lorem Ipsum available, but the majority
            have suffered alteration in some form, by injected humour, or randomised words which
            don't look even slightly believable?
          </td>
          <td>
            <span class="uk-label m-1"><i class="uil-label-alt"></i> Kişisel Gelişim</span>
            <span class="uk-label m-1"><i class="uil-label-alt"></i> Liderlik</span>
            <span class="uk-label m-1"><i class="uil-label-alt"></i> Yıl Sonu Soruları</span>
          </td>
          <td>
            <div class="d-flex justify-content-end  align-items-center">
              <toggle-button :sync="true" color="#ae71ff"/>
            </div>
          </td>
        </tr>

        </tbody>
      </table>

    </div>
  </div>
</div>
</template>

<script>
import SortDropdown from "@/view/components/dropdown/SortDropdown.vue";
export default {
  name: "QuestionPool",
  components:{SortDropdown},
  data() {
    return {
      sortOption: [
        {key: 'text-asc', sort: 'id', order: 'asc', label: this.$t("sorting.a_z")},
        {key: 'text-desc', sort: 'id', order: 'desc', label: this.$t("sorting.z_a")},
        {key: 'created_at-asc', sort: 'created_at', order: 'asc', label: this.$t("sorting.created_at_asc")},
        {key: 'created_at-desc', sort: 'created_at', order: 'desc', label: this.$t("sorting.created_at_desc")},
      ],
    };
  },

}
</script>
<style scoped>

</style>