<template>
  <div class="exam-properties-menu" :id="menuId" :uk-offcanvas="'flip: true; bg-close: false; show: true'">
    <div class="uk-offcanvas-bar">
      <button :uk-close="true" class="uk-offcanvas-close" type="button"/>
      <h4><i class="uil-cog"/> {{$t('exam.exam_features')}} </h4>
      <hr/>

      <div class="row" :class="{'disable-exam-properties-menu':isPrePostActive}">
        <div class="col-xl-12 m-auto">
          <div class="form-group row mb-2">
            <label class="col-form-label" for="exam_title">
              {{ $t("exam.name") }}<span class="required">*</span>
            </label>
            <input id="exam_title" v-model="examData.exam_name" :placeholder="$t('exam.enter_name')"
                   class="form-control mb-2" type="text"/>
          </div>

          <div class="form-group row mb-2" v-if="false">
            <label class="col-md-8 col-form-label p-0">
              Sınav Geçme Notu Olsun
            </label>
            <div class="col-md-4">
              <div class="d-flex justify-content-end  align-items-center">
                <toggle-button v-model="examData.has_exam_pass_grade" :sync="true" color="#ae71ff"/>
              </div>
            </div>
          </div>

          <div class="form-group row mb-2">
            <label class="col-form-label col-md-8 pl-0" for="passmark">
              {{ $t("exam.passmark") }} (0-100)<span class="required">*</span>
            </label>
            <input id="passmark" v-model="examData.passmark" :placeholder="$t('exam.enter_passmark')"
                   class="form-control col-md-4 mb-0" type="number"/>
          </div>

          <div class="form-group row mb-2" v-if="false">
            <label class="col-form-label col-md-8 pl-0">
              Sınavdan Başarılı Olamazsa...
            </label>
            <select v-model="examData.if_failed_from_exam" class="selectpicker2 form-control col-md-4 mb-0">
              <option selected value="retry">Sınavı Tekrar Çözer</option>
              <option value="report">Sınav Sonucu Raporuna Yansır</option>
            </select>
          </div>

          <div class="form-group row mb-2">
            <label class="col-form-label col-md-8 pl-0">
              {{ $t("exam.duration") }}<span class="required">*</span>
            </label>
            <input id="duration" v-model="examData.duration" :placeholder="$t('exam.enter_duration')"
                   class="form-control col-md-4 mb-0" type="number"/>
          </div>

          <div class="form-group row mb-3" v-if="false">
            <label class="col-form-label col-md-8 pl-0">
              Sınavdan Çıkarsa
            </label>
            <select v-model="examData.if_exit_from_exam" class="selectpicker2 form-control col-md-4 mb-0">
              <option selected value="continue">Tekrar kaldığı yerden devam edebilir.</option>
              <option value="done">Sınavı bitirmiş olur</option>
            </select>
          </div>

          <div class="form-group row mb-0" v-if="false">
            <label class="col-md-9 col-form-label p-0">
              Her sorunun bir süre sınırı olsun
            </label>
            <div class="col-md-3">
              <div class="d-flex justify-content-end align-items-center">
                <toggle-button v-model="examData.every_question_has_time_limit" :sync="true" color="#ae71ff"/>
              </div>
            </div>
            <span class="text-muted mb-3"><i class="uil-info-circle"/> <i>Soru alanından süreleri ekleyebilirsiniz.</i></span>
          </div>

          <div class="form-group row mb-0" v-if="false">
            <label class="col-md-9 col-form-label p-0">
              Her sorunun bir puanı olsun
            </label>
            <div class="col-md-3">
              <div class="d-flex justify-content-end align-items-center">
                <toggle-button v-model="examData.every_question_has_score" :sync="true" color="#ae71ff"/>
              </div>
            </div>
            <span class="text-muted mb-3"><i class="uil-info-circle"/> <i>Soru alanından puanları ekleyebilirsiniz.</i></span>
          </div>

          <div class="form-group row mb-0">
            <label class="col-md-9 col-form-label p-0">
              {{ $t("exam.is_mix_questions") }}
            </label>
            <div class="col-md-3">
              <div class="d-flex justify-content-end align-items-center">
                <toggle-button v-model="examData.is_mix_questions" :sync="true" color="#ae71ff"/>
              </div>
            </div>
          </div>

          <div class="form-group row mb-0">
            <label class="col-md-9 col-form-label p-0">
              {{ $t("exam.is_mix_answers") }}
            </label>
            <div class="col-md-3">
              <div class="d-flex justify-content-end align-items-center">
                <toggle-button v-model="examData.is_mix_answers" :sync="true" color="#ae71ff"/>
              </div>
            </div>
          </div>

          <div class="form-group row mb-0">
            <label class="col-md-9 col-form-label p-0">
              {{ $t("exam.show_right_answers") }}
            </label>
            <div class="col-md-3">
              <div class="d-flex justify-content-end align-items-center">
                <toggle-button v-model="examData.show_right_answers" :sync="true" color="#ae71ff"/>
              </div>
            </div>
          </div>

          <div class="form-group row mb-0">
            <label class="col-md-9 col-form-label p-0">
              {{ $t("exam.show_user_answers") }}
            </label>
            <div class="col-md-3">
              <div class="d-flex justify-content-end align-items-center">
                <toggle-button v-model="examData.show_user_answers" :sync="true" color="#ae71ff"/>
              </div>
            </div>
          </div>

          <div class="form-group row mb-0">
            <label class="col-md-9 col-form-label p-0">
              {{ $t("exam.show_result") }}
            </label>
            <div class="col-md-3">
              <div class="d-flex justify-content-end  align-items-center">
                <toggle-button v-model="examData.show_result" :sync="true" color="#ae71ff"/>
              </div>
            </div>
          </div>

          <div class="form-group row mb-0">
            <label class="col-md-9 col-form-label p-0">
              {{ $t("exam.is_compulsory") }}
            </label>
            <div class="col-md-3">
              <div class="d-flex justify-content-end  align-items-center">
                <toggle-button v-model="examData.is_compulsory" :sync="true" color="#ae71ff"/>
              </div>
            </div>
          </div>

          <div class="form-group row mb-0" v-if="false">
            <label class="col-md-9 col-form-label p-0">
              Sınav çoğaltılabilir olsun
            </label>
            <div class="col-md-3">
              <div class="d-flex justify-content-end  align-items-center">
                <toggle-button  :sync="true" color="#ae71ff"/>
              </div>
            </div>
          </div>

          <div class="form-group row mb-0">
            <label class="col-md-9 col-form-label p-0">
              Pre Post
            </label>
            <div class="col-md-3">
              <div class="d-flex justify-content-end  align-items-center">
                <toggle-button v-model="examData.pre_post"  :sync="true" color="#ae71ff"/>
              </div>
            </div>

            <span>{{$t('general.pre_post_means')}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExamPropertiesMenu',
  props: {
    menuId: {
      type: String,
      default: 'exam-properties'
    },
    initialData: {
      type: Object,
      default: () => {}
    },
    isPrePostActive:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      // api den değişkenleri kontrol et
      examData: {
        exam_name: '',
        passmark: 50, //max: 100
        if_failed_from_exam: 'retry',
        if_exit_from_exam:'continue',
        duration: 20,
        show_right_answers: true,
        show_user_answers: true,
        is_mix_questions: true,
        is_mix_answers: true,
        show_result: true,
        is_compulsory: true,
        has_exam_pass_grade:false,
        every_question_has_time_limit:false,
        every_question_has_score:false,
        pre_post:false,
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initializeData()
      UIkit.offcanvas(`#${this.menuId}`).show();
      this.createRandomExamName()
    });
  },

  methods: {
    initializeData() {
      if (this.initialData && Object.keys(this.initialData).length) {
        this.examData = {...this.initialData}
      }
    },

    createRandomExamName() {
      this.examData.exam_name= this.examData.exam_name || `${this.$t('exam.exam')}-` + (Math.floor(Math.random() * 1000) + 1);
    },

    handleNewExamMenu() {
      // refs for parent component
      return this.examData;
    },

  },
  watch: {
    initialData: {
      handler(newVal) {
        if (newVal && Object.keys(newVal).length) {
          this.examData = {...newVal};
        }
      },
      deep: true,
      immediate: true
    }
  },

}
</script>
<!--add uk-offcanvas-container to actived scroll on main parent componnet scrollToTop , do not delete the ->.uk-offcanvas-page, .uk-offcanvas-container -->

<style >
.uk-offcanvas-page, .uk-offcanvas-container {
  overflow-x: initial !important;
}
.disable-exam-properties-menu {
  pointer-events: none;  /* Kullanıcının tıklamalarını engeller */
  opacity: 0.5;
}
</style>
