<template>
  <div class="excel-question-importer">
    <div class="uk-card-body p-0 uk-grid-collapse uk-grid-large" uk-grid>
      <div class="uk-padding-small position-relative uk-width-1-3@m">
        <h5><i class="uil-table"/>  {{$t('general.add_with_excell')}}</h5>
        <div class="form-group row mb-3">
          <div class="col-md-12">
            <form action="api/something" enctype="multipart/form-data" method="post">
              <input id="fileToUpload" :disabled="is_loading" accept=".xls, .xlsx"
                     name="fileToUpload" type="file" @change="setFile" @click="resetFileInfo"/>
            </form>

            <a class="mb-4" href="#" style="font-size: 14px; color: #ae71ff"
               @click="exportSampleFile"><i class="uil-download-alt"> </i> {{$t('general.download_excel_template')}} </a>
            <i class="uil-info-circle" style="font-size: 1rem"
               uk-tooltip="title: Kullanıcıları sisteme eklemek amacıyla şablon haline getirilmiş excel dokümanını indirin; pos: bottom-center"></i>
          </div>
        </div>
        <hr class="mt-5"/>
      </div>

      <div class="uk-padding-small uk-width-expand d-flex flex-column justify-content-between px-2" style="border-left: 1px solid #ccc">
        <default-loading v-if="is_loading" />
        <template>
          <div class="uk-position-relative text-center" style="overflow-y: scroll;">
            <div class="table-responsive">
              <table class="uk-table uk-table-striped uk-table-divide">
                <thead>
                <tr>
                  <th scope="col">{{$t('evaluation_survey.question')}}*
                  </th>
                  <th scope="col">A
                  </th>
                  <th scope="col">B
                  </th>
                  <th scope="col">C
                  </th>
                  <th scope="col">D
                  </th>
                  <th scope="col">E
                  </th>
                  <th scope="col"> {{$t('share_management.content.right_answer')}}
                  </th>
                </tr>
                </thead>
                <tbody class="list">
                <template v-for="(item, index) in importedExam">
                  <tr v-if="item" :key="index">
                    <td>{{ item[0] }}</td>
                    <td>{{ item[1] }}</td>
                    <td>{{ item[2] }}</td>
                    <td>{{ item[3] }}</td>
                    <td>{{ item[4] }}</td>
                    <td>{{ item[5] }}</td>
                    <td>{{ item[6] }}</td>
                    <td>{{ item[7] }}</td>
                    <td>{{ item[8] }}</td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>


<script>
import DefaultLoading from "@/view/components/loading/DefaultLoading.vue";

import readXlsxFile from "read-excel-file";
import axios from "axios";
import moment from "moment";

export default {
  name: "ExcelQuestionImporter",
  data() {
    return {
      is_loading: false,
      file: null,
      file_value: null,
      importedExam: null,
      fileInfo: {
        name: "",
        count: 0,
        file: null,
      },
      is_added: false,
    };
  },
  components: {DefaultLoading},
  methods: {
    removeIndex(key) {
      let obj = this.importedExam;
      this.importedExam = delete obj[key];
      this.importedExam = obj;
    },
    resetFileInfo() {
      this.is_added = false;
      this.fileInfo.file = null;
      $("#fileToUpload").val("");
    },
    setFile(e) {
      this.file_value = e.target.value;
      this.file = e.target.files[0];
      this.excelToJSON();
    },
    exportSampleFile() {
      let self = this;
      let config = {
        responseType: "blob",
        headers: this.apiHeaders,
      };
      axios
          .post(
              process.env.VUE_APP_BACKEND_APP_URL + "/api/exam/exam-download-sample-excel",
              [],
              config
          )
          .then((response) => {
            let fileURL = window.URL.createObjectURL(new Blob([response.data]));
            let fileLink = document.createElement("a");

            fileLink.href = fileURL;
            let day = moment().format("YYYYMMDDHHMMSS");
            let fileName = "exam_sample_" + day + ".xlsx";
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((error) => {
            console.error(error);
          });
    },
    updateImporteValues(values, count, fileName) {
      count--;
      this.importedExam = values;
      this.fileInfo.name = fileName;
      this.fileInfo.count = count;
      this.is_added = count > 0;
      this.is_loading = false;
      this.$emit('imported', values)
    },
    excelToJSON() {
      if (this.file) {
        this.is_loading = true;
        let fileName = this.file_value ? this.file_value.split(/(\\|\/)/g).pop() : "";
        let count;
        this.fileInfo.file = this.file;
        readXlsxFile(this.file).then((rows) => {
          delete rows[0];
          count = rows.length;
          this.updateImporteValues(rows, count, fileName);
        });
      }
    },
  },
};
</script>
<style scoped>

</style>