import { render, staticRenderFns } from "./ExcelQuestionImporter.vue?vue&type=template&id=2d13cba3&scoped=true&"
import script from "./ExcelQuestionImporter.vue?vue&type=script&lang=js&"
export * from "./ExcelQuestionImporter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d13cba3",
  null
  
)

export default component.exports