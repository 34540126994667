<template>
  <div class="chat-card">
    <!-- Chat alanı -->
    <div ref="questionContainer" class="chat-content">
      <div class="messages-container" style="background-image: url('/media/images/messenger-bg.png');">
        <anzera-question-card ref="anzeraQuestionsRef" :question-list="questionList"/>
      </div>
    </div>

    <!-- Footer alanı -->
    <div class="chat-footer">
      <div v-if="hasError" style="color: red !important;">{{ errorMessage }}</div>
      <div v-if="infoMessage" class="loading-text">{{ infoMessage }} <span class="dots" /></div>

      <div class="uk-flex uk-align-center uk-margin-small-top">
        <select v-model="fileType"
                class="uk-select file-type-select uk-form-small uk-form-width-small uk-margin-small-right">
          <option v-for="(option,index) in fileTypeOptions" :key="index" :value="option.value">{{
              option.label
            }}
          </option>
        </select>
        <input v-model="question_subject" :placeholder="$t('general.subject_of_your_question')"
               class="uk-input uk-form-small uk-form-width-medium" type="text">

        <button :class="{'loading-btn-disabled':isLoading}" :disabled="isLoading"
                class="uk-button uk-button-primary ml-2" type="button" @click="createQuestion">
          <span class="dots"/> {{$t('general.create')}}
        </button>

      </div>

      <div class="uk-flex uk-flex-between uk-margin-small-top">
        <div class="question-count">
          <select v-model="questionCount"
                  class="uk-select  question-count-select uk-form-small uk-form-width-small uk-margin-small-right"
                  style="width:50px;">
            <option v-for="item in 10" :key="item" :value="item">{{ item }}</option>
          </select>
          <span>{{$t('general.create_question_count')}}</span>
        </div>
        <div class="uk-flex anzera-submit-buttons">

          <button :class="{'loading-btn-disabled':transferExam}"
                  :disabled="!questionList.length || transferExam"
                  class="choose-all-btn uk-button uk-button-primary uk-margin-small-right"
                  type="button" @click="chooseAllQuestions">
             {{$t('general.select_all')}}
          </button>

          <button :class="{'loading-btn-disabled':transferExam}"
                  :disabled="!questionList.length || transferExam"
                  class="uk-button uk-button-primary uk-margin-small-right"
                  type="button" @click="addToQuestionList">
            <span class="dots"/> {{ imageTranslateText  || $t('general.import_selected_questions')}}
          </button>

          <button :disabled="!questionList.length" class="uk-button uk-button-small uk-margin-small-left" type="button"
                  @click="cleanQuestions"> {{ $t('general.clear') }}
          </button>
          <button class="uk-button uk-button-small uk-margin-small-left" type="button"
                  @click="cancelProcess"> {{ $t('general.give_up') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AnzeraQuestionCard from "@/view/components/anzera-power/anzera-question-card/anzera-question-card.vue";
import AnzeraQuestionCardSkeleton
  from "@/view/components/anzera-power/anzera-question-card/anzera-question-card-skeleton.vue";
import DefaultLoading from "@/view/components/loading/DefaultLoading.vue";
import {nextTick} from "vue";

export default {
  name: "examAdd",
  components: {DefaultLoading, AnzeraQuestionCard, AnzeraQuestionCardSkeleton},
  props: {
    file: {
      type: String,
      default: ''
    },
    isSingleSelection:{
      default: false,
      type:Boolean
    },
    imageTranslateText: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      fileTypeOptions: [
        {value: "text", label: this.$t('general.text')},
        {value: "image", label: this.$t('general.photo')},
        //{ value: "file", label: "Dosya" },
      ],

      question_subject: '',
      fileType: this.file || 'text',
      questionCount: 1,
      questionList: [],
      isLoading: false,
      transferExam: false,
      errorMessage: '',
      infoMessage: '',
      hasError: false,
      rawImageData: [],
    }
  },
  methods: {
    createQuestion() {
      this.rawImageData = []
      this.hasError = false
      this.isLoading = true;

      const maxRetries = 3;
      const retryDelay = 3000;
      let retryCount = 0;

      const apiEndpoints = {
        text: "/api/ai/store",
        image: "/api/ai/storeImage",
      };

      const payloads = {
        text: {type: "text", question_count: this.questionCount, question_subject: this.question_subject},
        image: {image_count: this.questionCount, image_subject: this.question_subject},
      };

      const makeApiCall = () => {
        this.infoMessage = retryCount > 0
            ? this.$t('general.anzera-error-message-try') + ` (${retryCount}/${maxRetries})`
            : '';

        // soruları grupla , bu sayede ekranda chat box mantığında her soruyu kendi grubu içinde gösterebilriz
        const groupId = Date.now();


        axios.post(apiEndpoints[this.fileType], payloads[this.fileType])
            .then(async (res) => {
              let newItems = [];

              // text ve image için gelen data içerikleri farklı , aynı türe cevir
              if (this.fileType === 'text') {
                newItems = res?.data.map((item, index) => ({
                  ...item,
                  type: this.fileType,
                  askedQuestion: this.question_subject,
                  groupId,
                }));
              } else if (this.fileType === 'image') {
                newItems = res?.data?.data.map((item, index) => ({
                  ...item,
                  type: this.fileType,
                  askedQuestion: this.question_subject,
                  question: this.question_subject,
                  groupId,
                }));
              }

              if(this.isSingleSelection){
                //soru şıklarında tek soru olusturuyoruz
                this.questionList = [
                  ...newItems
                ].map((item, index) => ({
                  ...item,
                  orderNo: index, // 0 dan
                }));
              }else {
                // her item degerine orderNo ekle
                this.questionList = [
                  ...this.questionList,
                  ...newItems
                ].map((item, index) => ({
                  ...item,
                  orderNo: index, // 0 dan
                }));
              }

              this.infoMessage = '';
              this.isLoading = false;
              this.hasError = false;
            })
            .catch((err) => {
              console.log(`API call failed (attempt ${retryCount + 1}/${maxRetries + 1}):`, err);

              if (retryCount < maxRetries) {
                retryCount++;
                this.infoMessage = this.$t('general.anzera-error-message-try') + ` (${retryCount}/${maxRetries})`;

                setTimeout(() => {
                  makeApiCall();
                }, retryDelay);

              } else {
                this.hasError = true;
                this.errorMessage = this.$t('general.anzera-error-message');
                this.infoMessage = '';
                this.isLoading = false;
              }
            })
      };

      makeApiCall();
    },
    async importImages(imageUrls) {
      try {

        // Track which image paths we've already seen
        const processedImagePaths = new Set();
        const uniqueResponses = [];

        // Process each image URL
        for (const image of imageUrls) {
          const response = await axios.post("/api/ai/import-image", {
            image_url: image.url,
          });

          const responseData = {
            ...response.data,
            ...image,
          };

          // Only add to our results if this is a unique image path
          if (!processedImagePaths.has(responseData.image_path)) {
            processedImagePaths.add(responseData.image_path);
            uniqueResponses.push(responseData);
          } else {
            console.warn("Duplicate image path detected:", responseData.image_path);
          }
        }

        return uniqueResponses;
      } catch (error) {
        console.error("Error loading images:", error);
        return [];
      }
    },

    cleanQuestions() {
      this.questionCount = 1
      this.questionList = []
      this.rawImageData = []
      this.question_subject = ''
    },

    cancelProcess() {
      this.$emit("onCancelled");
    },

    chooseAllQuestions() {
      this.$refs.anzeraQuestionsRef.checkAllQuestions()
    },

    async addToQuestionList() {
      if (!this.questionList.length) return;

      const selectedQuestionIndexes = this.$refs.anzeraQuestionsRef.handleSelectedQuestions()

      if (!selectedQuestionIndexes.length) {
        //seçim yapılmazsa
        this.hasError = true;
        this.errorMessage = this.$t('Lütfen en az bir soru seçiniz');
        return
      }

      // orderNo değerine göre filtreleme, seçilen soruların id sine göre filtre yap
      const filteredQuestionList = this.questionList.filter(item => selectedQuestionIndexes.includes(item.orderNo));

      this.isLoading = true;
      this.infoMessage = this.$t('general.anzera_info_message');
      this.transferExam = true
      this.hasError = false;

      try {
        const textQuestions = filteredQuestionList.filter(q => q.type === 'text');
        const imageQuestions = filteredQuestionList.filter(q => q.type === 'image');

        let processedImages = [];
        if (imageQuestions.length > 0) {
          processedImages = await this.importImages(imageQuestions);
        }

        const updatedQuestionList = [...textQuestions, ...processedImages];
        // orderno ya göre sırala , [...textQuestions, ...processedImages] burada order no değişiyor
        updatedQuestionList.sort((a, b) => Number(a.orderNo) - Number(b.orderNo));

        console.log("updatedQuestionList", updatedQuestionList)

        this.$emit("handleQuestions", {data: updatedQuestionList});

      } catch (e) {
        this.hasError = true;
        this.errorMessage = this.$t('general.anzera-process-error');

      } finally {
        this.isLoading = false;
        this.infoMessage = '';
        this.hasError = false;
        this.errorMessage = '';
        this.transferExam = false
      }

    },

    scrollToBottom() {
      nextTick(() => {
        const container = this.$refs.questionContainer;
        if (container) {
          container.scrollTo({
            top: container.scrollHeight,
            behavior: 'smooth',
          });
        }
      });
    },

  },
  watch: {
    questionList(newVal) {
      if (newVal.length) {
        this.scrollToBottom(); // Yeni veri eklenince en alta kaydır
      }
    }
  },
}
</script>

<style lang="scss" scoped src="./anzera-power-chat.scss"/>